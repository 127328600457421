import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'check_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="check_circle_outline_24"><g fill="currentColor"><path d="M14.964 9.264a.9.9 0 011.272 1.272l-4.6 4.6a.9.9 0 01-1.272 0l-2.6-2.6a.9.9 0 011.272-1.272L11 13.227z" /><path clip-rule="evenodd" d="M12 2.1c5.467 0 9.9 4.433 9.9 9.9s-4.433 9.9-9.9 9.9-9.9-4.433-9.9-9.9S6.533 2.1 12 2.1zm0 1.8A8.1 8.1 0 003.9 12a8.1 8.1 0 008.1 8.1 8.1 8.1 0 008.1-8.1A8.1 8.1 0 0012 3.9z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CheckCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CheckCircleOutline: FC<Icon24CheckCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CheckCircleOutline as any).mountIcon = mountIcon;

export default Icon24CheckCircleOutline;
