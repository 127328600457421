import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'favorite_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="favorite_outline_24"><path clip-rule="evenodd" d="M12.554 4.694a.6.6 0 00-1.108 0l-1.614 3.9a.9.9 0 01-.774.554l-4.125.266a.6.6 0 00-.357 1.05l3.267 2.859a.9.9 0 01.282.888l-.93 3.852a.6.6 0 00.883.66l3.472-2.003a.9.9 0 01.9 0l3.472 2.004a.6.6 0 00.883-.66l-.93-3.853a.9.9 0 01.282-.888l3.267-2.858a.6.6 0 00-.357-1.05l-4.125-.267a.9.9 0 01-.774-.554zm-2.772-.689c.818-1.976 3.618-1.976 4.436 0l1.4 3.383 3.565.23c2.136.138 3.037 2.792 1.426 4.201l-2.856 2.5.802 3.322c.494 2.05-1.706 3.696-3.533 2.642L12 18.539l-3.022 1.744C7.15 21.337 4.95 19.69 5.445 17.64l.802-3.322-2.856-2.5c-1.611-1.41-.71-4.063 1.426-4.201l3.566-.23z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24FavoriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24FavoriteOutline: FC<Icon24FavoriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24FavoriteOutline as any).mountIcon = mountIcon;

export default Icon24FavoriteOutline;
