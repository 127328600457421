import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'like_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="like_outline_20"><path d="M13.629 2.5A5.371 5.371 0 0119 7.871c0 2.837-1.081 4.275-5.702 7.869l-1.993 1.55a2.125 2.125 0 01-2.61 0l-1.993-1.55C2.082 12.146 1 10.708 1 7.87A5.371 5.371 0 016.371 2.5c1.256 0 2.403.46 3.414 1.346l.215.197.215-.197c1.01-.886 2.158-1.346 3.414-1.346zm0 1.5c-1.124 0-2.137.53-3.055 1.622a.75.75 0 01-1.148 0C8.508 4.53 7.496 4 6.37 4A3.871 3.871 0 002.5 7.871c0 2.238.838 3.352 5.123 6.685l1.993 1.55a.625.625 0 00.768 0l1.993-1.55c4.09-3.182 5.04-4.341 5.118-6.386l.005-.299A3.871 3.871 0 0013.629 4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20LikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20LikeOutline: FC<Icon20LikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20LikeOutline as any).mountIcon = mountIcon;

export default Icon20LikeOutline;
